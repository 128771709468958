import React from "react"

import filHello from "../../images/fillip/fil-hello.jpg"
import codeImage from "../../images/js.jpg"

const styles = {
  pageWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  pageInner: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    backgroundColor: "white",
    padding: "50px 30px 0 80px",
    boxShadow: "inset 0px 0px 2px 2px #9f9f9f",
    borderRadius: "20px",
  },
  contentWrapper: {
    padding: "10px 0 30px 80px",
    maxWidth: "500px",
  },
  link: {
    fontWeight: "600",
    textDecoration: "none",
    color: "#00B123",
  },
}

const emailOnClick = () => {
  const part1 = "mailto:hello@f"
  const part2 = "illippeyton.com"
  window.location.replace(part1 + part2)
}

const SplashPageContent = () => (
  <>
    <style
      dangerouslySetInnerHTML={{
        __html: `
    body {
      background-image: url(${codeImage});
      background-repeat: no-repeat;
      background-size: cover;
    }
    @media (max-width: 500px) {
      .pageInner {
        grid-template-columns: 1fr!important;
        padding: 20px!important;
      }
      .contentWrapper {
        padding-left: 0!important;
      }
    }
  `,
      }}
    />
    <div style={styles.pageWrapper}>
      <div className="pageInner" style={styles.pageInner}>
        <div>
          <img src={filHello} />
        </div>
        <div className="contentWrapper" style={styles.contentWrapper}>
          <h1>Hello!</h1>
          <p>
            I'm in the process of re-building my site, yet again. In the
            meantime, you can contact me a few ways:
          </p>
          <ul style={styles.ul}>
            <li>
              <a
                style={styles.link}
                href="https://www.linkedin.com/in/fillip-p-67542211/"
              >
                LinkedIn
              </a>
            </li>
            <li>
              <a style={styles.link} href="https://github.com/fillippeyton">
                GitHub
              </a>
            </li>
            <li>
              <a style={styles.link} href="#" onClick={emailOnClick}>
                Email
              </a>
            </li>
          </ul>
          <p>- Fil</p>
        </div>
      </div>
    </div>
  </>
)

export default SplashPageContent
